import React, { useState, useEffect, useMemo } from 'react';
import isEqual from 'lodash/isEqual';
import IconButton from '@mui/material/IconButton';
import { BrandGuideColor, Color, SimpleColor } from 'types/color.type';
import Icon from 'components/ui-components-v2/Icon';
import Button from 'components/ui-components-v2/Button';
import cloneDeep from 'helpers/cloneDeep';
import Translation from 'components/data/Translation';
import { ColorPicker } from 'components/ui-components-cape/ColorPicker';
import { ColorHelpers } from 'helpers/colors.helpers';
import '../styles/main.scss';

interface Props {
    value: (string | BrandGuideColor | Color | SimpleColor)[];
    useGradients?: boolean;
    useAlpha?: boolean;
    presetColors?: BrandGuideColor[];
    defaultValue?: (string | BrandGuideColor)[];
    disableFirst?: boolean;
    onMutation: (value: string) => void;
    onBlur?: () => void;
    maxColors?: number;
    colorPickerSize?: 'small' | 'medium';
}

const DynamicColorList = ({
    value,
    defaultValue,
    useGradients,
    useAlpha,
    presetColors,
    disableFirst,
    onMutation,
    onBlur,
    maxColors = 30,
    colorPickerSize
}: Props) => {
    /**
     * Converts the preset colors to the correct format.
     */
    const newDefaultValue = useMemo(() => {
        if (!defaultValue) return [];

        return defaultValue.map((color) => {
            if (typeof color === 'string') {
                return ColorHelpers.getColorObjectFromString(color);
            }

            return ColorHelpers.convertBrandGuideColorToColor(color);
        });
    }, [defaultValue]);

    const newValue = useMemo(() => {
        if (!value) return [];

        return value.map((color) => {
            if (typeof color === 'string') {
                return ColorHelpers.getColorObjectFromString(color);
            }

            if (ColorHelpers.isBrandGuideColor(color)) {
                return ColorHelpers.convertBrandGuideColorToColor(color);
            }

            if (ColorHelpers.isSimpleColor(color)) {
                return ColorHelpers.convertSimpleColorToColor(color);
            }

            return color;
        });
    }, [value]);

    const [colors, setColors] = useState<Color[]>(newValue || newDefaultValue);

    // Sync the value from the parent component
    useEffect(() => {
        if (newValue && !isEqual(newValue, colors)) {
            setColors(newValue);
        }
    }, [newValue]);

    //If the default value is used send it to the component above.
    useEffect(() => {
        if (!value) {
            onMutation(ColorHelpers.getCssColor(newDefaultValue[0]));
        }
    }, []);

    /**
     * Changes the color
     * @param index Index of the color
     * @param value The value of the color
     */
    const onColorInput = (index: number, value: string | Color | SimpleColor) => {
        const newColors = cloneDeep(colors);

        if (typeof value === 'string') {
            newColors[index] = value;
        } else {
            newColors[index] = value;
        }

        if (isEqual(colors, newColors)) return;
        setColors(newColors);
        onMutation(newColors);
    };

    /**
     * Removes a color
     * @param index Index of the color
     */
    const removeColor = (index: number) => {
        const newColors = cloneDeep(colors);
        newColors.splice(index, 1);

        setColors(newColors);
        onMutation(newColors);
    };

    /**
     * Adds a color
     */
    const addColor = () => {
        const newColors = cloneDeep(colors);
        newColors.push(ColorHelpers.getColorObjectFromString(ColorHelpers.DEFAULT_HEX));

        setColors(newColors);
        onMutation(newColors);
    };

    return (
        <div className="input__dynamic-color-list" onBlur={onBlur}>
            {colors.map((color, i) => {
                const isDisabled = disableFirst && i === 0;

                return (
                    <div key={i} className="input__dynamic-color-list__color">
                        <ColorPicker
                            color={color}
                            useGradients={useGradients}
                            useAlpha={useAlpha}
                            onChange={(value) => onColorInput(i, value)}
                            presetColors={presetColors}
                            disabled={isDisabled}
                            size={colorPickerSize}
                        />
                        <IconButton
                            onClick={() => removeColor(i)}
                            disabled={isDisabled}
                            style={{ opacity: isDisabled ? 0 : 1 }}
                            size="small"
                            className="delete-color">
                            <Icon>close</Icon>
                        </IconButton>
                    </div>
                );
            })}

            {colors.length < maxColors && (
                <Button className="input__dynamic-color-list__add-color" onClick={addColor} color="primary" size="medium" fullWidth variant="outlined">
                    {Translation.get('input.dynamicColorList.addColor', 'common')}
                </Button>
            )}
        </div>
    );
};

export default DynamicColorList;

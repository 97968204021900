import '../styles/main.scss';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Axios from 'axios';
import Select from 'components/ui-components-v2/Select';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import Request from 'components/data/Request';

/**
 * Facebook Ad Set
 * This shows a select box with ad sets from Facebook
 */
export default class FacebookAdSet extends Component {
    static propTypes = {
        /** Equal to the output of the field, requiresTranslation and maxLength should be copied from this input to the output.*/
        value: PropTypes.any,
        /** Function to call when the value has changed */
        onMutation: PropTypes.func,
        /** This is a disabled object */
        disabled: PropTypes.bool
    };

    static defaultProps = {
        disabled: false,
        onMutation: () => {}
    };

    constructor(props) {
        super(props);

        this.state = {
            options: [],
            value: props.value ? props.value : 0
        };
    }

    componentDidMount() {
        // Fetch a token to connect to the publish api
        Request.post('publishing/getToken', { type: 'metadata' }).then((result) => {
            if (!result.success) {
                SnackbarUtils.error("We coulnd't connect you to the Facebook API.");
                return;
            }
            this.token = result.data.token;
            if (this.props.campaignId) {
                this.requestList();
            }
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.campaignId !== prevProps.campaignId) {
            this.requestList();
        }
        if (this.props.value !== prevProps.value) {
            this.setState({ value: this.props.value });
        }
    }

    /**
     * Request the result list from Facebook
     */
    requestList = () => {
        const { campaignId } = this.props;
        Axios.post(process.env.PUBLISH_ENGINE + 'V2/metadata/facebookmarketing/adSets', {
            token: this.token,
            campaignId: campaignId
        }).then((response) => {
            if (response.data.success) {
                this.setState({ options: response.data.data });
            }
        });
    };

    /**
     * Handle radius
     * @param {*} radius
     * @param {*} index
     */
    handleChange = (e) => {
        const adSetId = e.target.value;

        this.setState({
            value: adSetId
        });
        this.props.onMutation(adSetId);
    };

    render() {
        const { value, options } = this.state;

        return (
            <div className="input__facebook-adset">
                <Select native value={value} onChange={this.handleChange} variant="outlined" fullWidth margin="dense">
                    <option value={0}>Select an ad set</option>
                    {options.map((item, index) => (
                        <option value={item.id} key={index}>
                            {item.name}
                        </option>
                    ))}
                </Select>
            </div>
        );
    }
}
